<template>
  <div>
    <v-card>
      <v-card-title>
       {{old_data.musteri_adi}}  ({{old_data.from}} - {{old_data.to}}) {{ $t('GorevPlani.MANUEL_ICIN_PLAN_DETAY') }}
        <!--<download-excel
          :data="PersonellerListesi"
          :name="$t('Personeller.PersonelExcelFileName')"
          :worksheet="$t('Personeller.PersonelExcelFileName')"
          :fields="ExcelFields"
          :header="ExcelHeader"
          class="ml-3"
        >
          <img
            src="@/assets/images/misc/excel.png"
            style="width: 25px"
          />
        </download-excel>-->
      </v-card-title>
    </v-card>
      <div style="display: flex;width: 100%;flex-wrap: wrap;" class='py-3'>
        <v-simple-table class="my-table">
          <template v-slot:default>
            <thead>
              <tr>
                <th
                  v-for="(header,x) in old_data.headers"
                  :key="header.value"
                  class="text-center"
                  :colspan="x>0 ? 4 : 1"
                >
                  {{ translateDay(header.text) }}
                </th>
              </tr>
              <tr>
                <th></th>
                <th
                  v-for="i in CalculatedHeaderLength"
                  :key="i"
                  colspan="2"
                >
                  {{ i % 2 == 0 ? "Bitiş Saat" : "Başla Saat" }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="(item, i) in old_data.items"
                :key="i"
              >
                <td>{{ item[0].soyisim + ',' + item[0].isim }}</td>
                <td
                  v-for="c in item"
                  :key="c.gorev_id"
                  colspan="4"
                >
                  <v-row>
                    <v-col class="d-flex">
                      <v-text-field
                        v-model="c.baslama_saati"
                        type="time"
                        required
                      ></v-text-field>

                      <v-text-field
                        v-model="c.bitis_saati"
                        class="ml-5"
                        type="time"
                        required
                      ></v-text-field>
                    </v-col>
                  </v-row>
                </td>
              </tr>
            <!--<tr
              v-for="(item,i) in old_data.items" :key='i'
            >
              <td>{{ item[0].soyisim+","+item[0].isim }}</td>
              <td colspan='4'
                  v-for='c in item' :key='c.gorev_id'
              >
                <v-row>
                  <v-col md='6'>

                    <v-text-field
                      v-model="c.baslama_saati"
                      type="time"
                      required
                    ></v-text-field>
                  </v-col>
                  <v-col md='6'>
                    <v-text-field
                      v-model="c.bitis_saati"
                      type="time"
                      required
                    ></v-text-field>
                  </v-col>
                </v-row>
              </td>

            </tr>-->
            </tbody>
          </template>
        </v-simple-table>
      </div>
      <div style="display: flex;justify-content: center;width: 100%">
        <v-btn
          color="primary"
          width="300px"
          @click="Olustur"
        >
          {{ $t('Personeller.kaydet') }}
        </v-btn>
      </div>
  </div>
</template>
<script>
import { mdiWindowClose } from '@mdi/js'
import api from '@/boot/axios'

export default {
  name: 'PersonelGuncelle',
  data() {
    return {
      icons: {
        mdiWindowClose,
      },
      form: [],
      old_data: [],
    }
  },
  computed: {
    CalculatedHeaderLength() {
      if (Array.isArray(this.old_data.headers)) {
        return (this.old_data.headers.length - 1) * 2
      }

      return 0
    },
  },

  mounted() {
    // eslint-disable-next-line no-prototype-builtins
    if (this.$route?.params.hasOwnProperty('id')) {
      this.getManuelData(this.$route.params.id)
    } else {
      this.$router.push({ name: 'gorev-mutabakat' })
    }
  },
  methods: {
    translateDay(day) {
      if (!day.includes('-')) return `${day.split(' ')[0]} ${this.$t(day.split(' ')[1])}`

      const [baslama, bitis] = day.split('-')

      return `${baslama.split(' ')[0]} ${this.$t(baslama.split(' ')[1])} - ${bitis.split(' ')[0]} ${this.$t(bitis.split(' ')[1])} `
    },
    async getManuelData(islem_kodu) {
      // eslint-disable-next-line camelcase
      const { data } = await api.post('gorevplani-islem', { id: islem_kodu })

      const mapped = data.result.items
      mapped.sort((a, b) => a.calisma_tarihi - b.calisma_tarihi)
      const postData = {
        from: data.result.from,
        to: data.result.to,
        musteri_adi: data.result.musteri_adi,
        items: mapped,
        headers: data.result.headers,
      }
      this.old_data = postData
    },
    async Olustur() {
      const data2 = []
      // eslint-disable-next-line no-plusplus,no-empty
      this.old_data.items.forEach((items, k) => {
        // eslint-disable-next-line no-shadow
        Object.keys(items).forEach(k => {
          data2.push(items[k])
        })
      })

      const { data } = await api.post('gorevplani-detayli-guncelle', data2)
      console.log('DX:', data)
      if (data.code === 201) {
        const vm = this
        setTimeout(() => {
          vm.$router.push({ name: 'gorev-mutabakat' })
        }, 2000)
      }

    },
  },

}
</script>

<style scoped>
.my-table > .v-data-table__wrapper > table,th,td {
  border:1px solid black;
}
</style>
